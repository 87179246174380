//import './bootstrap';
import Alpine from 'alpinejs'

window.Alpine = Alpine

Alpine.start()

document.querySelectorAll('.sortable-column').forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      let order = item.dataset.sort;
      let dir = item.dataset.sortDir;
      let url = item.dataset.url;
    if(url.indexOf('?')<1){
        window.location.href = url+'?&order='+order+'&order_dir='+dir;
    }else{
        window.location.href = url+'&order='+order+'&order_dir='+dir;
    }

    })
})
